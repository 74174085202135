import React, { useState } from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/images/team/Ash 2.png";

function Ash() {
  return (
    <>
      <section className="team-details pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="team-details__image wow fadeInUp"
                data-wow-duration="1500ms">
                <img src={BG} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="team-details__content"
                style={{ textAlign: "justify", paddingRight: "60px" }}>
                <div className="team-details__floated">Ash</div>
                <div className="block-title text-left">
                  <p className="block-title__tagline">Senior Loan Analyst</p>
                  <h2 className="block-title__title">Ash (Akansha) Patel</h2>
                </div>
                {/* <div className="team-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div> */}
                <p className="team-details__highlight">
                  KEEN &amp; CREATIVE PROBLEM SOLVER
                </p>
                <p className="team-details__text">
                  With a well-rounded experience in the mortgage industry, Ash
                  has gained extensive knowledge of mortgage products and
                  services. She now drives her business relationships with
                  polite communication and analytical skills.
                </p>
                <p className="team-details__text">
                  Despite the simple saying ‘customer is the king’, Ash places
                  the needs of her clients front and center of every case she
                  works on; determined to only secure the best value, loans, and
                  returns.
                </p>
              </div>
            </div>
            <div className="container">
              <hr className="block-separator" />
            </div>
            <div
              className="col-lg-12"
              style={{ textAlign: "justify", padding: "40px" }}>
              <p className="team-details__text">
                Having a naturally happy and positive temperament, Ash easily
                builds rapport with her clients. Hence, it comes as no surprise
                that Ash is frequently recommended to new clients for her calm
                and relaxed working vibes.
              </p>
              <p className="team-details__text">
                Despite seeming laid back, Ash is steadfast and timely with her
                deliveries. Like many of her peers, she is also invested in
                keeping up to date with the property market and key industry
                trends. Armed with the needed expertise and skill, Ash aims to
                leave steaks of success in her wake.
              </p>
              <p className="team-details__text">
                Upon reflecting on life, Ash has cultivated an inquisitive
                approach to life and life’s challenges. If she could turn back
                time, she would tell her younger self,{" "}
                <b style={{ fontStyle: "italic" }}>
                  “Don’t be afraid to ask questions. There are no stupid
                  questions”.
                </b>
              </p>
              <p className="team-details__text">
                Her thoughts on property investment:
              </p>
              <ul className="team-details__text">
                <li>Start early!</li>
                <li>Funnel funds back into clearing investment loans</li>
                <li>Create and sustain multiple sources of income</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <hr className="block-separator" />
      </div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-telephone"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Have any question?</p>
                  <a
                    className="contact-info-one__link"
                    href="tel:+61 (466) 648 549">
                    0466 648 549
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Write us email</p>
                  <a
                    className="contact-info-one__link"
                    href="mailto:needhelp@company.com">
                    info@securefinance.com.au
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Visit anytime</p>
                  <a className="contact-info-one__link" href="#">
                  401/7 Maitland Pl <br /> Norwest NSW 2153
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ash;
