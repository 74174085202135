import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Img from "../../assets/new-icons/newsletter.png";
import BGImg from "../../assets/resourses/checkeligibilitydelete.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";

function Get() {
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);

  const handleClose = () => setShow(false);
  const [validated, setValidated] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    notes: "",
  });

  const handleSubmit = async (event) => {
    setloading(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
   

    try {
      const response = await fetch(
        "https://securefinance-backend-cecb18c06878.herokuapp.com/api/v1/submit-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
    
      // Check if the response is not OK
      if (!response.ok) {
        if (response.status === 404) {
          // Handle 404 error
          alert("User Already Subscribed.");
        } else {
          // Handle other status codes
          alert(`Error: ${response.status} - ${response.statusText}`);
        }
        setloading(false);
        return; // Exit the function
      }
    
      // If successful
      alert("Form submitted successfully.");
      setloading(false);
    } catch (error) {
      // Handle network or other unexpected errors
      alert("An error occurred while submitting the form.");
      setloading(false);
    }



    setShow(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const isFormValid = () => {
    const requiredFields = ["firstName", "lastName", "email"];
    return requiredFields.every((field) => formData[field].trim() !== "");
  };

  console.log(formData);
  return (
    <>
      <Helmet>
        <title>News - Secure Finance</title>
        <meta
          name="description"
          content="Join us for exclusive updates: events, news, property trends, insights."
        />
        <link rel="canonical" href="https://securefinance.com.au/news" />
      </Helmet>
      <section className="testimonials-one pt-40 pb-40">
        <Container>
          <div>
            <div className="container" data-wow-duration="1500ms">
              <div className="row row-gutter-y-0">
                <div className="col-md-12">
                  <div className="text-center">
                    <p className="block-title__tagline">News</p>
                    <h2 className="block-title__title mb-5">
                      Be a part of our community<br />Subscribe to our newsletter!
                    </h2>
                  </div>
                </div>
                <Container>
                  <Row className="mt-3">
                    <Col xs={12} sm={12} lg={{ span: 1, offset: 2 }} md={2}>
                      <div className="main-menu__nav-2">
                        <img src={Img} style={{ width: "150px" }} />
                      </div>
                    </Col>
                    <Col lg={{ span: 7, offset: 1 }} md={10} xs={12} sm={12}>
                      <div className="p-1">
                        <p className="service-card-four__title">
                          Secure Finance is actively involved in our community
                          and occasionally hosts events <br /> for you to be
                          more involved as well. Below are some things you can
                          expect from our newsletters:
                        </p>
                        <p
                          className="service-card-four__text_loan col-md-12"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                          }}
                        >
                          <li>Invitation to Seminars and Networking Events</li>
                          <li>Industry News & Insights</li>
                          <li>Property Trends and Predictions</li>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Row>
                  <Col lg={{ span: 4, offset: 4 }} sm xs md>
                    <Form.Control
                      size="sm"
                      type="email"
                      name="email"
                      placeholder="sample@gmail.com"
                      aria-describedby="basic-addon2"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={{ span: 3, offset: 0 }} sm xs md>
                    <Button
                      onClick={() => setShow(true)}
                      variant="outline-primary"
                      size="sm"
                      className="rounded-pill button1"
                    >
                      <div
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        SUBSCRIBE TODAY
                      </div>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Fill in your details to subscribe our Newsletter Now!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
              <Container>
                <Row>
                  <Col lg={8} md={8}>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="First name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom02"
                        >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          className="mt-3"
                          controlId="validationCustom03"
                        >
                          <Form.Label>Phone Number (optional)</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Please provide a Phone Number (optional)"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Phone Number
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mt-3"
                          as={Col}
                          md="12"
                          controlId="validationCustom04"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Please provide an Email Address."
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Email Address.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Col>
                  <Col lg={4} md={4} className="news-letter-image">
                    <img src={BGImg} style={{ width: "100%" }} />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={!isFormValid() || loading}
                variant="success"
                onClick={handleSubmit}
              >
                {loading ? <Spinner animation="border" /> : "SUBSCRIBE"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </section>
    </>
  );
}

export default Get;
