import React, { useState } from "react";
import { Link } from "react-router-dom";
import BG from "../../../assets/images/team/Gouthami 2.png";

function Gouthami() {
  return (
    <>
      <section className="team-details pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="team-details__image wow fadeInUp"
                data-wow-duration="1500ms">
                <img src={BG} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="team-details__content"
                style={{ textAlign: "justify", paddingRight: "60px" }}>
                <div className="team-details__floated">Gouthami</div>
                <div className="block-title text-left">
                  <p className="block-title__tagline">Senior Loan Analyst</p>
                  <h2 className="block-title__title">Gouthami Minupala</h2>
                </div>
                {/* <div className="team-details__social">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-pinterest"></i>
                  </Link>
                  <Link to="#">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div> */}
                <p className="team-details__highlight">
                  INDUSTRY EXPERT & PROCESS GATEKEEPER
                </p>

                <p className="team-details__text">
                  With 7 years of in-depth experience in the mortgage industry,
                  Gouthami has mastered her skills from onboarding the clients
                  to having them settled with the best outcome possible. She has
                  developed a reputation for delivering exceptional services.{" "}
                </p>
                <p className="team-details__text">
                  Driven by a problem-solving mindset, she embraces challenges
                  that arise during the entire mortgage broking journey with a
                  can-do and will-win attitude.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <hr className="block-separator" />
      </div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pt-40 pb-40">
              <div className="team-details__content">
                <p className="team-details__text">
                  Her approach is simple. Deliver outstanding service to clients
                  and build meaningful relationships that go beyond
                  transactions.
                </p>
                <p className="team-details__text">
                  Known for her meticulous attention to detail, she has always
                  hit the mark for accuracy and precision, ensuring that all
                  necessary documentation and information are thoroughly vetted
                  whilst meeting deadlines.
                </p>
                <p className="team-details__text">
                  She also ensures that her knowledge of loan products stays
                  current to offer valuable guidance to borrowers; helping them
                  navigate through different loan options and to ascertain the
                  best solution tailored to their specific needs.
                </p>
                <p className="team-details__text">
                  Adapting to evolving industry trends and technology has become
                  a norm for Gouthami as she seeks out new methods that enhance
                  efficiency and productivity in loan processing.
                </p>
                <p className="team-details__text">
                  Industry Awards: Mortgage Professional Australia - Elite Women
                  2023
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6">
              <div className="team-progress__content">
                <div className="team-progress__item">
                  <h4 className="team-progress__title">Consulting</h4>
                  <div className="team-progress__bar">
                    <div
                      className="team-progress__bar__inner count-bar"
                      data-percent="67%"
                      style={{ width: "67%" }}>
                      <div className="team-progress__bar__text count-text">
                        67%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-progress__item">
                  <h4 className="team-progress__title">Credit Card</h4>
                  <div className="team-progress__bar">
                    <div
                      className="team-progress__bar__inner count-bar"
                      data-percent="46%"
                      style={{ width: "46%" }}>
                      <div className="team-progress__bar__text count-text">
                        46%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-progress__item">
                  <h4 className="team-progress__title">Personal Loan</h4>
                  <div className="team-progress__bar">
                    <div
                      className="team-progress__bar__inner count-bar"
                      data-percent="28%"
                      style={{ width: "28%" }}>
                      <div className="team-progress__bar__text count-text">
                        28%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </section>
      <div className="container">
        <hr className="block-separator" />
      </div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-telephone"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Have any question?</p>
                  <a
                    className="contact-info-one__link"
                    href="tel:+61 (478) 891 679">
                    0478 891 679
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Write us email</p>
                  <a
                    className="contact-info-one__link"
                    href="mailto:needhelp@company.com">
                    info@securefinance.com.au
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Visit anytime</p>
                  <a className="contact-info-one__link" href="#">
                  401/7 Maitland Pl <br /> Norwest NSW 2153
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gouthami;
