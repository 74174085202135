import React, { useState } from "react";

function VirtualMeeting() {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: getCurrentDate(),
    time: getCurrentTime(),
    subject: "",
    message: "",
  });

  const [showSuccessBox, setShowSuccessBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const emailData = {
      type: "website-email",
      to: ["info@securefinance.com.au"],
      cc: [],
      body: `
        <div style="font-family: Arial, sans-serif; line-height: 1.5; color: #333;">
            <h2 style="color: #007bff;">Booking Details</h2>
            <p><strong>Sender's Name:</strong> ${formData.firstName} ${
        formData.lastName
      }</p>
            <p><strong>Sender's Email:</strong> ${formData.email}</p>
            <p><strong>Sender's Phone:</strong> ${formData.phone}</p>
            <p><strong>Booking Date:</strong> ${formData.date}</p>
            <p><strong>Booking Time:</strong> ${formData.time}</p>
            <p><strong>Message:</strong><br>${formData.message.replace(
              /\n/g,
              "<br>"
            )}</p>
        </div>
      `,
      subject: formData.subject,
      attachments: [],
    };

    try {
      const response = await fetch(
        "https://apiadmin.securefinance.com.au/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );

      if (response.ok) {
        setShowSuccessBox(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          date: "2023-12-03",
          time: "10:00",
          subject: "",
          message: "",
        });

        setTimeout(() => setShowSuccessBox(false), 3000); // Hide after 3 seconds
      } else {
        console.error("Failed to submit booking.");
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="faq-form pt-120 pb-120">
        <div className="container">
          <div className="block-title text-center">
            <p
              className="block-title__tagline"
              style={{ fontFamily: "Montserrat", marginBottom: "20px" }}
            >
              BOOK A FACE-TO-FACE MEETING
            </p>
            <h2
              className="block-title__title"
              style={{ fontFamily: "Montserrat" }}
            >
              Visit our office for a Complimentary
              <br /> Consultation Session
            </h2>
          </div>
          {loading && (
            <div
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Sending...
            </div>
          )}
          {showSuccessBox && (
            <div
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Booking submitted successfully!
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className="form-one contact-form-validated"
          >
            <div className="row row-gutter-y-20 row-gutter-x-20">
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-12">
                <textarea
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="thm-btn thm-btn--dark-hover rounded-pill"
                >
                  Submit Booking
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default VirtualMeeting;
