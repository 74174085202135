import React from "react";
import { Link } from "react-router-dom";
import Img from "../../../assets/images/team/Sakib.png";
import Img1 from "../../../assets/images/team/Ajay_Profile.png";
import Img2 from "../../../assets/images/team/Gouthami_Profile.png";
//import Img3 from "../../../assets/images/team/Rishab_Profile.png";
//import Img4 from "../../../assets/images/team/Ash_Profile.png";
import Img5 from "../../../assets/images/team/team-1-1.jpg";

function First() {
  const teamMembers = [
    {
      name: "Sakib Manzoor",
      position: "Principle Finance Broker",
      image: Img,
      link: "/sakib-manzoor",
    },
    {
      name: "Ajay Banda",
      position: "Senior Finance Broker",
      image: Img1,
      link: "/ajay-banda",
    },
    {
      name: "Gouthami Minupala",
      position: "Finance Broker",
      image: Img2,
      link: "/gouthami-minupala",
    },
   /* {
      name: "Rishab Wadhwa",
      position: "Finance Broker",
      image: Img3,
      link: "/not-found",
    },
    {
      name: "Ash (Akansha) Patel",
      position: "Senior Loan Analyst",
      image: Img4,
      link: "/ash",
    },*/
  ];

  return (
    <>
      <section className="team-grid pt-40 pb-40 ">
        <div className="container">
          <div className="row row-gutter-y-30">
            {teamMembers.map((member, index) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay={`${index * 100}ms`}
                key={index}>
                <div className="team-card">
                  <Link to={member.link}>
                    <div className="team-card__image">
                      <img src={member.image} alt={member.name} />
                      <div className="team-card__social">
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                        <Link to="#">
                          <i className="fab fa-pinterest"></i>
                        </Link>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="team-card__content">
                      <div className="team-card__content__inner text-center">
                        <h3 className="team-card__title">
                          <Link to={member.link}>{member.name}</Link>
                        </h3>
                        <p className="team-card__designation">
                          {member.position}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
